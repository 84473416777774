import React from 'react';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import { useApolloClient, useMutation } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useTracking } from 'react-tracking';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { searchToObject } from '../../../../helpers/locationSearchToJson';
import useMe from '../../../../hooks/useMe';
import { FILTER_INIT_VALUES } from '../../../../constants/filterInitValues';
import { SAVE_USER_SESSION_FILTERS } from '../../../../queries/interview/interview';

const StyledMenu = withStyles((theme) => ({
  paper: {
    marginTop: '2px',
    borderRadius: '5px',
    border: '1px solid',
    borderColor: theme.palette.info.main,
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    '& .MuiListItemIcon-root': {
      minWidth: '18px',
      marginRight: '10px',
    },
    '& .MuiTypography-root': {
      fontWeight: 'bold',
      lineHeight: '18px',
      '& a': {
        color: theme.palette.text.primary,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiTypography-root': {
        color: theme.palette.common.white,
        '& a': {
          color: theme.palette.common.white,
        },
      },
    },
    '&:active': {
      backgroundColor: theme.palette.primary.dark,
      '& .MuiTypography-root': {
        color: theme.palette.common.white,
        '& a': {
          color: theme.palette.common.white,
        },
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  logo: {
    height: '40px',
    width: '40px',
    overflow: 'hidden',
    border: '1px solid',
    borderColor: theme.palette.info.main,
  },
  dropdown: {
    height: '40px',
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    padding: '6px 0',
    textTransform: 'initial',
    '& .MuiButton-endIcon': {
      marginLeft: '10px',
      marginRight: '0',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      '& svg path': {
        stroke: theme.palette.primary.main,
      },
    },
  },
  userDrop: {
    minWidth: '36px',
    padding: '0',
    backgroundColor: 'rgba(256,256,256,0.1)',
    borderRadius: '5px',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: 'rgba(256,256,256,0.2)',
    },
    [theme.breakpoints.up('md')]: {
      '&:active': {
        backgroundColor: 'rgba(256,256,256,0.4)',
      },
    },
  },
  avatar: {
    display: 'flex',
    padding: '8px 0',
  },
}));

const UserProfileDropdown = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { me, loading } = useMe();
  const { trackEvent } = useTracking();
  const client = useApolloClient();
  const location = useLocation();
  const [saveUserSessionFiltersCache] = useMutation(SAVE_USER_SESSION_FILTERS);
  const activeTab = useSelector((state) => state.sideBar.activeTab, isEqual);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    trackEvent({
      viewName: 'logout',
      path: location.pathname,
      getDict: JSON.stringify(searchToObject(location.search)),
    });
    // TODO need async await
    if (activeTab === 'reading') {
      saveUserSessionFiltersCache({
        variables: {
          filterData: JSON.stringify(FILTER_INIT_VALUES),
        },
      });
    }
    // we need this for tracking purposes
    setTimeout(() => {
      Cookies.remove('sessionid');
      Cookies.remove('csrftoken');
      client.clearStore();
      window.localStorage.clear();
      window.location.href = '/accounts/login';
    }, 300);
  };

  if (isEmpty(me) || loading) return '';

  return (
    <>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        color="inherit"
        disableRipple
        onClick={handleClick}
        className={classes.userDrop}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {me.avatar
            ? <img alt="my avatar" width="100%" height="auto" src={me.avatar} />
            : (
              <Box className={classes.avatar}>
                <img alt="avatar placeholder" src="/images/icons/profile_dark.svg" />
              </Box>
            )}
        </Box>
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem disableRipple>
          <ListItemIcon>
            <img src="/images/icons/account_settings.svg" alt="account settings" />
          </ListItemIcon>
          <ListItemText>
            <Link to="/accounts/profile" onClick={handleClose}>
              Profile Settings
            </Link>
          </ListItemText>
        </StyledMenuItem>
        <StyledMenuItem disableRipple onClick={handleLogOut}>
          <ListItemIcon>
            <img src="/images/icons/logout.svg" alt="logout" />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

UserProfileDropdown.propTypes = {};

export default UserProfileDropdown;
