import React, { useState, useEffect } from 'react';
import fetch from 'cross-fetch';
import Box from '@material-ui/core/Box';
import { useMutation, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import EraAutocomplete from '../../../../../../../../../components/EraAutocomplete';
import {
  ALL_COMPANIES_QUERY,
} from '../../../../../../../../../queries/interview/interview';
import {
  ADD_COMPANY_TO_FOLLOWING_MUTATION,
  ALL_FOLLOWING_COMPANIES_QUERY,
  // ALL_FOLLOWING_TICKERS_QUERY,
} from '../../../../../../../../../queries/interview/following';
import { ReactComponent as DotIcon } from '../../../../../../../../../assets/icons/dotIcon.svg';

const useStyles = makeStyles((theme) => ({
  loadProgress: {
    color: theme.palette.info.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  tickerDesc: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    marginBottom: '2px',
  },
  tickerTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '12px',
    color: theme.palette.primary.main,
  },
  tickerSector: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '12px',
    color: theme.palette.text.secondary,
  },
  spanDivider: {
    lineHeight: '12px',
    '& svg': {
      margin: '0 6px !important',
      minWidth: '4px !important',
    },
  },
  searchIcon: {
    backgroundImage: 'url(https://mosaic-stream.s3.amazonaws.com/icons/search.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left',
    textIndent: '25px',
  },
}));

export default function TickerSelect() {
  const classes = useStyles();
  const [options, setOptions] = React.useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [skipLocal, setSkipLocal] = useState(true);
  const [selectedOption, setSelectedOption] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const [addToFollowing, { loading }] = useMutation(ADD_COMPANY_TO_FOLLOWING_MUTATION, {
    errorPolicy: 'all',
    refetchQueries: [
      { query: ALL_FOLLOWING_COMPANIES_QUERY },
    ],
  });

  const {
    data: { allTickers = {} } = {},
    refetch,
  } = useQuery(ALL_COMPANIES_QUERY, {
    variables: {
      first: 100,
    },
    skip: skipLocal,
  });

  useEffect(() => {
    if (!isEmpty(allTickers)) {
      if (!allTickers.totalCount) {
        setOptions([]);
      } else {
        const companies = allTickers.results.map((el) => ({
          symbol: el?.title,
          securityName: el?.description,
          sector: el?.sector?.title,
        }));
        setOptions(companies);
        setSkipLocal(true);
      }
    }
  }, [allTickers]);

  const handleInputChange = async (value) => {
    if (value) {
      const response = await fetch(`https://cloud.iexapis.com/stable/search/${value}/?token=pk_046300acb8a54bdbb7e8c6de0dd630f8`);
      const companies = await response.json();
      if (companies.length > 0) {
        setOptions(companies);
      } else {
        setSkipLocal(false);
        refetch({ search: value });
      }
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    handleInputChange(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleAdd = (value) => {
    if (!isEmpty(value)) {
      setSelectedOption(value);
      addToFollowing({
        variables: { tickerTitle: value.symbol },
      }).then(({ data: { addCompanyToFollowingMutation = {} } = {} }) => {
        setSelectedOption('');
        if (!isEmpty(addCompanyToFollowingMutation.errors)) {
          enqueueSnackbar(addCompanyToFollowingMutation.errors.map((err) => (
            `${err.field}: ${err.messages.join(', ')}`
          )),
          { variant: 'error', autoHideDuration: 4000 });
        } else {
          enqueueSnackbar('Added to Watchlist.',
            { variant: 'success', autoHideDuration: 4000 });
        }
      });
    }
  };

  const getOptionLabel = (option) => {
    let label = '';
    if (option.symbol) label += option.symbol;
    if (option?.securityName) label += ` (${option.securityName})`;
    if (option.sector) label += ` - ${option.sector}`;
    return label;
  };

  const getOptionItem = (option) => {
    let optionHeight = 10;
    if (option?.securityName) {
      optionHeight += Math.ceil(option.securityName.length / 28) * 18 + 2;
    }
    const optionSymbol = option.symbol?.length || 0;
    const optionSector = option.sector?.length || 0;
    optionHeight += Math.ceil((optionSymbol + optionSector + 3) / 29) * 20;

    const layout = (
      <Box>
        {option?.securityName
        && <Box className={clsx(classes.tickerDesc, 'ticker-desc')}>{option.securityName}</Box>}
        <Box>
          {option.symbol
          && <Box className={clsx(classes.tickerTitle, 'ticker-title')} component="span">{option.symbol}</Box>}
          {(option.symbol && option.sector)
          && (
            <Box className={clsx(classes.spanDivider, 'span-divider')} component="span">
              <DotIcon />
            </Box>
          )}
          {option.sector
          && <Box className={clsx(classes.tickerSector, 'ticker-sector')} component="span">{option.sector}</Box>}
        </Box>
      </Box>
    );

    return {
      optionHeight,
      layout,
    };
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      mb="12px"
      position="relative"
      className="xlink-search-watchlist-entry-form"
    >
      <EraAutocomplete
        options={options}
        placeholder="Search for Companies"
        inputClass={classes.searchIcon}
        hideOpenIcon
        hideClearIcon
        disableCloseOnSelect={false}
        multiple={false}
        value={selectedOption}
        onChange={(event, value) => handleAdd(value)}
        onInputChange={(event, value) => { setSearchQuery(value); }}
        getOptionLabel={getOptionLabel}
        getOptionSelected={(option, value) => option.symbol === value.symbol}
        getOptionItem={getOptionItem}
      />
      {loading && <CircularProgress size={24} className={classes.loadProgress} />}
    </Box>
  );
}
