import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.secondary,
    padding: '32px 0',
    [theme.breakpoints.up('md')]: {
      padding: '41px 0',
    },
  },
  footer: {
    fontSize: '14px',
    lineHeight: '18px',
    padding: '0 20px',
  },
  link: {
    color: theme.palette.primary.main,
    margin: '0 10px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const Footer = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <Box
      display="block"
      displayPrint="none"
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="center"
        className={classes.footer}
      >
        <Box
          textAlign="center"
        >
          Copyright &copy; 2021 Stream by Mosaic Research Management Inc. - All right Reserved.
        </Box>
        <Box
          textAlign="center"
          m={{ xs: '0', md: '0 10px' }}
        >
          <a
            href="https://legal.streamrg.com/privacy.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Privacy Policy
          </a>
          <a
            href="https://legal.streamrg.com/tou.pdf"
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a>
        </Box>
      </Box>
    </Box>
  );
};

Footer.defaultProps = {
  className: '',
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
