import React from 'react';
import * as PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import isEqual from 'lodash/isEqual';
import { connect, useDispatch } from 'react-redux';
import DefaultSettings from './components/DefaultSettings';
import {
  FollowingCompanies,
  FollowingIndustries,
  FollowingSectors,
  FollowingKeywords,
} from './components';
import { writeFilterData } from '../../../../../../../../actions/filterActions';
import { hideSideBar } from '../../../../../../../../actions/sidebarActions';
import UploadWatchlistTool from './components/UploadWatchlistTool';
// import { mostPopular } from '../../../../../../../../constants/orderingOptions';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    overflow: 'hidden',
    margin: '-20px',
  },
  headingPosition: {
    position: 'relative',
    margin: '20px 20px 12px',
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '25px',
  },
}));

const Following = ({ filterData, handleWriteFilterData }) => {
  const [expanded, setExpanded] = React.useState('panelCompanies');
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  });
  const [open, setOpen] = React.useState(false);
  const handleFilterSelecion = (values) => {
    const extraData = {};
    // if (values.tickers && values.tickers.length > 0) {
    //   if (filterData.o === null) {
    //     extraData.o = mostPopular;
    //   }
    // }
    handleWriteFilterData({ ...values, ...extraData });
    if (isMobile) {
      dispatch(hideSideBar());
    }
  };
  const handleExpanded = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box className={classes.container}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.headingPosition}
      >
        <Box
          display="flex"
          alignItems="center"
        >
          <Box className={classes.title}>
            Watchlist
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <UploadWatchlistTool open={open} setOpen={setOpen} isIconBtn />
          <DefaultSettings />
        </Box>
      </Box>

      <Box>
        <FollowingCompanies
          // filterDataTickers={filterData.tickers}
          handleExpandedAccordion={handleExpanded('panelCompanies')}
          expandedAccordion={expanded === 'panelCompanies'}
          ariaControls="panelCompanies-content"
          accordionId="panelCompanies-header"
          sectionTitle="Companies"
          trackingClass="xlink-watchlist-companies"
          handleFilterSelection={handleFilterSelecion}
          setOpen={setOpen}
        />

        <FollowingIndustries
          filterDataIndustries={filterData.industries}
          handleExpandedAccordion={handleExpanded('panelIndustries')}
          expandedAccordion={expanded === 'panelIndustries'}
          ariaControls="panelIndustries-content"
          accordionId="panelIndustries-header"
          sectionTitle="Industries"
          trackingClass="xlink-watchlist-industries"
          handleFilterSelection={handleFilterSelecion}
        />

        <FollowingSectors
          filterDataSectors={filterData.sectors}
          handleExpandedAccordion={handleExpanded('panelSectors')}
          expandedAccordion={expanded === 'panelSectors'}
          ariaControls="panelSectors-content"
          accordionId="panelSectors-header"
          sectionTitle="Sectors"
          trackingClass="xlink-watchlist-sectors"
          handleFilterSelection={handleFilterSelecion}
        />

        <FollowingKeywords
          filterDataKeywords={filterData.keywords}
          handleExpandedAccordion={handleExpanded('panelKeywords')}
          expandedAccordion={expanded === 'panelKeywords'}
          ariaControls="panelKeywords-content"
          accordionId="panelKeywords-header"
          sectionTitle="Keywords"
          trackingClass="xlink-watchlist-keywords"
          handleFilterSelection={handleFilterSelecion}
        />
      </Box>
    </Box>
  );
};

Following.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filterData: PropTypes.object.isRequired,
  handleWriteFilterData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { filterState } = state;
  return {
    filterData: filterState.filterData,
  };
};

const areEqual = (prevProps, nextProps) => isEqual(prevProps.filterData, nextProps.filterData);

export default React.memo(
  connect(
    mapStateToProps,
    {
      handleWriteFilterData: writeFilterData,
    },
  )(Following),
  areEqual,
);
