import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { ReactComponent as AddWatchlistImg } from '../../../../../../../../../../assets/images/addWatchlist.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '22px',
    color: theme.palette.text.secondary,
    textAlign: 'center',
    marginTop: '8px',
  },
}));

const FollowingPlaceholder = ({
  children, content,
}) => {
  const classes = useStyles();

  return (
    <Box height="calc(100vh - 232px)">
      <Box className={classes.container}>
        <AddWatchlistImg />
        <Box className={classes.text}>
          {children}
        </Box>
        {content}
      </Box>
    </Box>
  );
};

FollowingPlaceholder.defaultProps = {
  children: null,
  content: null,
};

FollowingPlaceholder.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
};

export default FollowingPlaceholder;
