import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link, useRouteMatch } from 'react-router-dom';
// import { useDecision } from '@optimizely/react-sdk';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import UserProfileDropdown from '../UserProfileDropdown/UserProfileDropdown';
import SidebarDrawer from './components/SidebarDrawer';
import sidebarTabs from '../../../../constants/sidebarTabs';
import { SAVE_USER_SESSION_FILTERS } from '../../../../queries/interview/interview';
import { FILTER_INIT_VALUES } from '../../../../constants/filterInitValues';
import {
  openCompanies, openFollowing, openReading, closeSideBar, hideSideBar,
} from '../../../../actions/sidebarActions';
import useMe from '../../../../hooks/useMe';
import useReadingListTitle from '../../../../hooks/useReadingListTitle';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/arrowLeftIcon.svg';
import { ReactComponent as CompaniesIcon } from '../../../../assets/icons/companiesIcon.svg';
import { ReactComponent as WatchlistIcon } from '../../../../assets/icons/watchlistIcon.svg';
import { ReactComponent as ReadingListIcon } from '../../../../assets/icons/readingListIcon.svg';
import { ReactComponent as DiscoveryIcon } from '../../../../assets/icons/discoveryIcon.svg';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '60px',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '60px',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    borderRight: '0',
    zIndex: '1301',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'fixed',
    top: '0',
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    outline: '0',
  },
  list: {
    height: '100%',
  },
  logo: {
    paddingTop: '0px',
    paddingBottom: '10px',
  },
  tab: {
    padding: '5px 12px 13px',
  },
  userTab: {
    position: 'absolute',
    bottom: '0px',
    padding: '5px 12px 12px',
  },
  closeSidebar: {
    minWidth: '36px',
    padding: '12px 8px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  toggleSidebarTab: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    cursor: 'pointer',
    fontSize: '10px',
    fontWeight: 'bold',
    lineHeight: '13px',
    textTransform: 'capitalize',
    letterSpacing: 'normal',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    [theme.breakpoints.up('md')]: {
      '&:active': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
  toggleCompaniesSidebar: {
    padding: '13px 0 8px',
  },
  toggleFollowingSidebar: {
    padding: '11px 0 8px',
  },
  toggleDiscoveryTab: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '10px',
    fontWeight: 'bold',
    lineHeight: '13px',
    color: theme.palette.common.white,
    transition: 'none',
    '& svg': {
      margin: '0 auto',
    },
  },
  badge: {
    position: 'absolute',
    right: '5px',
    top: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    height: '16px',
    minWidth: '16px',
    borderRadius: '8px',
    '&.xl': {
      minWidth: '22px',
      right: '2px',
    },
    '&.xxl': {
      minWidth: '28px',
      right: '0px',
    },
  },
  count: {
    display: 'inline-block',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '14px',
  },
  activeTab: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    '& svg path': {
      fill: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
    },
    '& > a': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up('md')]: {
      '&:active': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
      },
    },
  },
}));

const Sidebar = () => {
  // const FEATURE_KEY = 'reading_list_enabled';
  // const [decision] = useDecision(FEATURE_KEY);
  const classes = useStyles();
  const activeTab = useSelector((state) => state.sideBar.activeTab, isEqual);
  const sideBarVisible = useSelector((state) => state.sideBar.sideBarVisible, isEqual);
  const dispatch = useDispatch();

  const handleOpenCompanies = () => dispatch(openCompanies());
  const handleOpenWatchlist = () => dispatch(openFollowing());
  const readingListTitle = useReadingListTitle();
  const handleOpenReadingList = () => {
    window.analytics.track(`${readingListTitle} Tab Opened`);
    dispatch(openReading());
  };
  const handleCloseSidebar = () => dispatch(closeSideBar());
  const handleHideSidebar = () => dispatch(hideSideBar());

  const { me } = useMe();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  });

  const [saveUserSessionFiltersCache] = useMutation(SAVE_USER_SESSION_FILTERS);

  const handleLogoClick = () => {
    saveUserSessionFiltersCache({
      variables: {
        filterData: JSON.stringify(FILTER_INIT_VALUES),
      },
    });
    window.location.href = '/';
  };

  const handleClose = () => {
    if (isMobile) {
      handleHideSidebar();
    } else {
      handleCloseSidebar();
    }
  };

  const getWatchlistTotalCount = () => (
    me.sectorWatchlistUnreadCount
      + me.companyWatchlistUnreadCount
      + me.keywordWatchlistUnreadCount
      + me.industryWatchlistUnreadCount
  );
  const matchDiscovery = useRouteMatch({ path: '/discovery', exact: true });

  const readingListShouldDisplayed = () => me.numOfBookmardedInterviews > 0;

  return (
    <>
      {sideBarVisible
      && (
        <Box className={classes.drawer}>
          <Box className={classes.drawerPaper}>
            <List className={classes.list}>
              {isMobile
                ? (
                  <ListItem className={classes.tab}>
                    <Button
                      color="inherit"
                      className={classes.closeSidebar}
                      onClick={handleClose}
                      disableRipple
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <ArrowLeftIcon />
                      </Box>
                    </Button>
                  </ListItem>
                )
                : (
                  <ListItem className={classes.logo}>
                    <Link
                      to="/"
                      onClick={handleLogoClick}
                    >
                      <img
                        alt="LogoIcon"
                        src="/images/icons/logo_icon_dark.svg"
                      />
                    </Link>
                  </ListItem>
                )}

              {!isEmpty(me) && me.role !== 'COMPLIANCE'
                && (
                  <>
                    <ListItem
                      className={clsx({
                        [classes.toggleSidebarTab]: true,
                        [classes.toggleCompaniesSidebar]: true,
                        [classes.activeTab]: activeTab === sidebarTabs.companies,
                      })}
                      onClick={handleOpenCompanies}
                    >
                      <CompaniesIcon />
                      <Box mt="8px">
                        Companies
                      </Box>
                    </ListItem>
                    <ListItem
                      className={clsx({
                        [classes.toggleSidebarTab]: true,
                        [classes.toggleFollowingSidebar]: true,
                        [classes.activeTab]: matchDiscovery?.isExact,
                      })}
                    >
                      <Link
                        to="/discovery"
                        className={classes.toggleDiscoveryTab}
                      >
                        <DiscoveryIcon />
                        <Box mt="4px">
                          Discovery
                        </Box>
                      </Link>
                    </ListItem>
                    <ListItem
                      className={clsx({
                        [classes.toggleSidebarTab]: true,
                        [classes.toggleFollowingSidebar]: true,
                        [classes.activeTab]: activeTab === sidebarTabs.following,
                      })}
                      onClick={handleOpenWatchlist}
                    >
                      <WatchlistIcon />
                      <Box mt="4px">
                        Watchlist
                      </Box>
                      {getWatchlistTotalCount() > 0
                        && (
                        <Box
                          className={clsx(
                            classes.badge,
                            getWatchlistTotalCount() > 9 && 'xl',
                            getWatchlistTotalCount() > 99 && 'xxl',
                          )}
                        >
                          <Box className={classes.count}>
                            {getWatchlistTotalCount()}
                          </Box>
                        </Box>
                        )}
                    </ListItem>
                    {readingListShouldDisplayed()
                      && (
                      <ListItem
                        className={clsx({
                          [classes.toggleSidebarTab]: true,
                          [classes.toggleFollowingSidebar]: true,
                          [classes.activeTab]: activeTab === sidebarTabs.reading,
                        })}
                        onClick={handleOpenReadingList}
                      >
                        <ReadingListIcon />
                        <Box mt="6px">
                          {readingListTitle}
                        </Box>
                      </ListItem>
                      )}
                  </>
                )}
              <ListItem className={classes.userTab}>
                <UserProfileDropdown />
              </ListItem>
            </List>
          </Box>
        </Box>
      )}
      {!isEmpty(me) && me.role !== 'COMPLIANCE'
      && (
      <SidebarDrawer
        activeTab={activeTab}
        open={Boolean(activeTab) && sideBarVisible}
        variant={!isMobile ? 'persistent' : 'temporary'}
        handleClose={handleClose}
      />
      )}
    </>
  );
};

export default Sidebar;
