import React, { useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useMediaQuery } from '@material-ui/core';
import { Following, CompaniesList, ReadingList } from './components';
import { ReactComponent as ChevronLeftIcon } from '../../../../../../assets/icons/chevronLeftIcon.svg';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '280px',
    height: '100%',
    marginLeft: '60px',
  },
  root: {
    position: 'relative',
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    height: '100%',
    '&:hover > button': {
      display: 'block',
    },
    overflow: 'auto',
  },
  closeSidebar: {
    display: 'none',
    position: 'fixed',
    top: '50%',
    left: '325px',
    height: '30px',
    minWidth: '30px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    marginTop: '-15px',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      '& svg path': {
        fill: theme.palette.common.white,
      },
    },
  },
}));

const SidebarDrawer = ({
  open, variant, onClose, className, handleClose, activeTab,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const renderTab = useCallback(() => {
    if (activeTab === 'following') {
      return <Following />;
    }
    if (activeTab === 'companies') {
      return <CompaniesList />;
    }
    if (activeTab === 'reading') {
      return <ReadingList />;
    }

    return null;
  }, [activeTab]);

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <Box className={clsx(classes.root, className)} id="sidebar-content">

        {renderTab()}

        {isDesktop
        && (
          <Button
            color="inherit"
            className={classes.closeSidebar}
            onClick={handleClose}
            disableRipple
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ChevronLeftIcon />
            </Box>
          </Button>
        )}
      </Box>
    </Drawer>
  );
};

SidebarDrawer.defaultProps = {
  className: '',
  onClose: () => {},
  activeTab: null,
};

SidebarDrawer.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
};

export default SidebarDrawer;
