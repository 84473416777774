import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import { connect, useSelector, useDispatch } from 'react-redux';
import ReadingListItem from './components/ReadingListItem';
import {
  ALL_READING_LISTS_QUERY,
  UPDATE_READING_LIST_MUTATION,
  SAVE_USER_SESSION_FILTERS,
} from '../../../../../../../../queries/interview/interview';
import { FILTER_INIT_VALUES } from '../../../../../../../../constants/filterInitValues';
import ItemsSkeleton from '../ItemsSkeleton';
import CreateReadingList from './components/CreateReadingList';
import { cleanFilterData, writeFilterData } from '../../../../../../../../actions/filterActions';
import useReadingListTitle from '../../../../../../../../hooks/useReadingListTitle';
import { hideSideBar } from '../../../../../../../../actions/sidebarActions';
import { setLastRL } from '../../../../../../../../actions/readingListActions';
import useReadingList from '../../../../../../../../hooks/useReadingList';
import { ME_QUERY } from '../../../../../../../../queries/account';

const useStyles = makeStyles((theme) => ({
  readingContainer: {
    height: '100%',
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '25px',
  },
  headingPosition: {
    position: 'relative',
  },
  noLists: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    lineHeight: '18px',
  },
}));

const ReadingList = ({ filterData, handleWriteFilterData }) => {
  const classes = useStyles();
  const lastRL = useSelector((state) => state.readingListState.setLastRL, isEqual);
  const matchInterview = useRouteMatch({ path: '/:interviewId', exact: true });
  const matchHome = useRouteMatch({ path: '/', exact: true });
  const isInterviewPage = !isEmpty(matchInterview) || !isEmpty(matchHome);
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  });
  const { updateQuery: updateAllReadingListQuery, allReadingList, loading } = useReadingList();
  const [saveUserSessionFiltersCache] = useMutation(SAVE_USER_SESSION_FILTERS);

  const handleFilter = (key, value) => {
    if (!isInterviewPage) {
      history.push('/');
    }
    handleWriteFilterData({ [key]: value });
    if (isMobile) {
      dispatch(hideSideBar());
    }
  };

  const [dataCopy, setDataCopy] = useState([]);
  const [updateReadingList] = useMutation(UPDATE_READING_LIST_MUTATION);

  // automatically display first reading list
  useEffect(() => {
    if (allReadingList.totalCount > 0) {
      setDataCopy([...allReadingList.results]);
    }
    if (filterData.readingLists.length === 0 && !loading && !lastRL.id
      && allReadingList.totalCount > 0) {
      handleFilter('readingLists', [{ id: allReadingList.results[0].id, title: allReadingList.results[0].title }]);
    }
  }, [allReadingList, filterData.readingLists]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filterData.readingLists.length === 0 && lastRL.id) {
      handleFilter('readingLists', [lastRL]);
    }
    // TODO clear filters
    return () => {
      saveUserSessionFiltersCache({
        variables: {
          filterData: JSON.stringify(FILTER_INIT_VALUES),
        },
      });
      dispatch(cleanFilterData());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedItem = dataCopy[oldIndex];
    let start = 0;
    let end = 0;
    let newOrderIndex = 0;

    if (dataCopy.length > 1) {
      // it means that its not last index and not first
      if (newIndex !== dataCopy.length - 1 && newIndex !== 0) {
        start = dataCopy[newIndex - 1].orderIndex;
        end = dataCopy[newIndex + 1].orderIndex;
      } else if (newIndex === dataCopy.length - 1) {
        start = dataCopy[newIndex - 1].orderIndex;
        end = dataCopy[dataCopy.length - 1].orderIndex + 1.0;
      } else if (newIndex === 0) {
        end = dataCopy[newIndex + 1].orderIndex;
      }
      newOrderIndex = Math.random() * (start - end) + end;

      updateReadingList({
        variables: {
          readingListId: updatedItem.id,
          title: updatedItem.title,
          orderIndex: newOrderIndex,
        },
        refetchQueries: [
          { query: ALL_READING_LISTS_QUERY },
          { query: ME_QUERY },
        ],
        update: ((cache, { data }) => {
          updateAllReadingListQuery((previousQueryResult) => {
            const results = previousQueryResult.allReadingList.results.map((el) => {
              if (el.id === data.updateReadingList.readingList.id) {
                return {
                  title: data.updateReadingList.title,
                  numOfNewInterviews: data.numOfNewInterviews,
                  totalCount: data.totalCount,
                  orderIndex: data.orderIndex,
                };
              }
              return el;
            });

            return {
              allReadingList: {
                ...previousQueryResult.allReadingList,
                results,
              },
            };
          });
        }),
      });

      const updatedData = dataCopy.map((item) => {
        if (item.id === updatedItem.id) {
          return {
            ...updatedItem, orderIndex: newOrderIndex,
          };
        }
        return item;
      });
      const orderedData = sortBy(updatedData, ['orderIndex']);

      setDataCopy(orderedData);
    }
  };

  const SortableItem = SortableElement((item) => (
    /*eslint-disable */
    <li tabIndex={0} style={{ listStyle: 'none', zIndex: 9999 }}>
      <Box key={item.value.id}>
        <ReadingListItem
          itemName={item.value.title}
          itemId={item.value.id}
          unreadCount={item.value.numOfNewInterviews}
          onFilterBy={() => {
            handleFilter('readingLists', [{ id: item.value.id, title: item.value.title }]);
            dispatch(setLastRL(item.value.id, item.value.title));
          }}
        />
      </Box>
    </li>
    /* eslint-enable */
  ));

  const SortableItemsList = SortableContainer(({ items }) => (
    <ul>
      {items.map((value, index) => (
        <SortableItem key={`item-${value.id}`} index={index} value={value} />
      ))}
    </ul>
  ));

  const readingListTitle = useReadingListTitle();

  return (
    <Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="10px"
          className={classes.headingPosition}
        >
          <Box className={classes.title}>
            {readingListTitle}
          </Box>
        </Box>
      </Box>
      <Box className={classes.readingContainer}>
        <CreateReadingList placeholder={`New ${readingListTitle} Name`} />
        <Fade in={allReadingList.totalCount > 0 && !loading}>
          <Box>
            {(allReadingList.totalCount > 0 && !loading)
              && (
                <SortableItemsList
                  items={dataCopy}
                  onSortEnd={onSortEnd}
                  distance={1}
                  lockAxis="y"
                />
              )}
          </Box>
        </Fade>

        {!loading
          && (
            <Fade in={allReadingList.totalCount === 0 && !loading}>
              <Box className={classes.noLists}>{`No ${readingListTitle}s`}</Box>
            </Fade>
          )}

        {loading
          && (
          <Fade in={loading}>
            <ItemsSkeleton />
          </Fade>
          )}
      </Box>
    </Box>
  );
};

ReadingList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filterData: PropTypes.object.isRequired,
  handleWriteFilterData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { filterState } = state;
  return {
    filterData: filterState.filterData,
  };
};

const areEqual = (prevProps, nextProps) => isEqual(prevProps.filterData, nextProps.filterData);

export default React.memo(
  connect(
    mapStateToProps,
    {
      handleWriteFilterData: writeFilterData,
    },
  )(ReadingList),
  areEqual,
);
