import React, { useEffect, useReducer } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useMutation } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import {
  ME_QUERY,
  UPDATE_USER_SETTINGS_MUTATION,
} from '../../../../../../../../../../queries/account';
import PeriodMenu from '../FollowingItem/PeriodMenu';
import useMe from '../../../../../../../../../../hooks/useMe';
import { ReactComponent as Gear2Icon } from '../../../../../../../../../../assets/icons/gear2Icon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    // position: 'relative',
  },
  dropdown: {
    position: 'absolute',
    top: 28,
    right: 0,
    left: 0,
    zIndex: 1,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    backgroundColor: theme.palette.background.paper,
    padding: '10px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    marginBottom: '10px',
  },
  checkbox: {
    margin: '0 0 10px',
    '& .MuiTypography-root': {
      fontSize: '13px',
      fontWeight: '500',
      lineHeight: '16px',
      color: theme.palette.text.secondary,
    },
    '& .MuiButtonBase-root': {
      minHeight: '18px!important',
      padding: '0 5px!important',
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
    },
  },
  cancelBtn: {
    cursor: 'pointer!important',
    flexGrow: '1',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '0',
    textTransform: 'capitalize',
    marginRight: '5px',
    '&:hover': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.info.dark,
      backgroundColor: theme.palette.common.white,
    },
    '&:active': {
      borderColor: theme.palette.info.main,
      backgroundColor: theme.palette.common.white,
    },
  },
  saveBtn: {
    cursor: 'pointer!important',
    flexGrow: '1',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '0',
    textTransform: 'capitalize',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
    },
  },
  settings: {
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '&:hover svg path': {
      stroke: theme.palette.primary.main,
    },
  },
}));

const DefaultSettings = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [settings, setSettings] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
  );

  const handleEmailAlertChange = (event) => {
    setSettings({ ...settings, [event.target.name]: event.target.checked });
  };

  const handleFrequency = (value) => {
    setSettings({ ...settings, notifyPeriod: value });
  };

  const { me } = useMe();

  const [updateSettings] = useMutation(UPDATE_USER_SETTINGS_MUTATION, {
    refetchQueries: [
      { query: ME_QUERY },
    ],
  });

  useEffect(() => {
    if (me.settings) {
      setSettings(me.settings);
    }
  }, [me]);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
    if (me.settings) {
      setSettings(me.settings);
    }
  };

  const handleSave = () => {
    updateSettings({
      variables: {
        settingsId: settings.id,
        notifyPeriod: settings.notifyPeriod,
        emailAlerts: settings.emailAlerts,
      },
    }).then(({ data: { updateUserSettingsMutation = {} } = {} }) => {
      if (!isEmpty(updateUserSettingsMutation.errors)) {
        enqueueSnackbar(updateUserSettingsMutation.errors.map((err) => (
          `${err.field}: ${err.messages.join(', ')}`
        )),
        { variant: 'error', autoHideDuration: 4000 });
      } else {
        enqueueSnackbar('Saved.',
          { variant: 'success', autoHideDuration: 4000 });
        handleClickAway(false);
      }
    });
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className={classes.root}>
        <IconButton
          color="inherit"
          aria-label="settings"
          size="small"
          disableRipple
          className={classes.settings}
          onClick={handleClick}
        >
          <Gear2Icon />
        </IconButton>
        {open ? (
          <Box className={classes.dropdown}>
            <Box className={classes.title}>
              Default settings for all newly added items
            </Box>
            <FormControlLabel
              labelPlacement="start"
              label="Enable email alerts"
              className={classes.checkbox}
              control={(
                <Checkbox
                  checked={settings.emailAlerts}
                  onChange={handleEmailAlertChange}
                  name="emailAlerts"
                  color="primary"
                  disableRipple
                />
                        )}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Box
                fontSize="13px"
                fontWeight="500"
                lineHeight="16px"
                color={settings.emailAlerts ? 'text.secondary' : 'info.main'}
              >
                Email Frequency
              </Box>
              <PeriodMenu
                onChange={handleFrequency}
                disabled={!settings.emailAlerts}
                frequency={settings.notifyPeriod}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              mt="10px"
            >
              <Button
                size="small"
                color="inherit"
                disableRipple
                onClick={handleClickAway}
                className={classes.cancelBtn}
              >
                Cancel
              </Button>
              <Button
                size="small"
                color="inherit"
                disableRipple
                className={classes.saveBtn}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};

export default DefaultSettings;
