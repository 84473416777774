import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useMutation, useQuery } from '@apollo/client';
import { makeStyles, withStyles } from '@material-ui/styles';
import { DateRangeDelimiter, DateRangePicker } from '@material-ui/pickers';
import isValid from 'date-fns/isValid';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { formatIsoDate } from '../../../../../../../../helpers/dateHelpers';
import { dateFormat } from '../../../../../../../../constants/dateFormats';
import EraAutocomplete from '../../../../../../../../components/EraAutocomplete';
import { FILTER_INIT_VALUES } from '../../../../../../../../constants/filterInitValues';
import { INTERVIEW_PERSPECTIVE_OPTIONS_QUERY } from '../../../../../../../../queries/interview/filterOptions';
import {
  IndustriesFilter, SectorsFilter, TickersFilter, UsersFilter,
} from './components';
// import { mostPopular } from '../../../../../../../../constants/orderingOptions';
import { SAVE_USER_SESSION_FILTERS } from '../../../../../../../../queries/interview/interview';
import { writeFilterData } from '../../../../../../../../actions/filterActions';
import useMe from '../../../../../../../../hooks/useMe';
import { ReactComponent as CancelRectIcon } from '../../../../../../../../assets/icons/cancelRectIcon.svg';

const DatePickerField = withStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px',
      height: '30px',
      maxWidth: '112px',
      '& input': {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        height: '100%',
        color: theme.palette.text.primary,
        padding: '5px',
      },
      '& fieldset': {
        borderColor: theme.palette.info.main,
      },
      '&:hover': {
        '& fieldset': {
          borderColor: theme.palette.info.dark,
        },
      },
      '&.Mui-focused': {
        '& fieldset': {
          borderWidth: '1px',
          borderColor: theme.palette.primary.main,
        },
      },
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '25px',
  },
  clearFilters: {
    padding: '0',
    borderRadius: '0',
    letterSpacing: 'normal',
    '& .MuiButton-label': {
      textTransform: 'capitalize',
      color: theme.palette.text.secondary,
      '& .MuiButton-startIcon': {
        margin: '0 5px 0 0',
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '& svg path': {
        fill: theme.palette.primary.main,
      },
    },
    '&:active': {
      backgroundColor: theme.palette.info.main,
    },
  },
  label: {
    display: 'block',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    paddingBottom: '5px',
  },
  delimiter: {
    margin: '0 4px',
  },
  collapse: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover svg path': {
      stroke: theme.palette.primary.main,
    },
  },
  divider: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  dateContainer: {
    marginTop: '10px',
    '& .MuiPickersDateRangePickerInput-rangeInputsContainer': {
      flexDirection: 'row',
    },
  },
  applyBtn: {
    marginTop: '15px',
    width: '100%',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
      },
      '&:active': {
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
      },
    },
  },
}));

const Filters = ({ toggleFilters, filterData, handleWriteFilterData }) => {
  const classes = useStyles();

  const {
    data: {
      allInterviewPerspective = {},
    } = {},
  } = useQuery(INTERVIEW_PERSPECTIVE_OPTIONS_QUERY);

  const { me } = useMe();

  const [filterValues, setFilterValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      tickers: filterData.tickers,
      industries: filterData.industries,
      sectors: filterData.sectors,
      expert: filterData.expert,
      perspective: filterData.perspective,
      complianceStatus: filterData.complianceStatus,
      transcriptionStatus: filterData.transcriptionStatus,
      publishedAtFrom: filterData.publishedAtFrom,
      publishedAtTo: filterData.publishedAtTo,
      callDateFrom: filterData.callDateFrom,
      callDateTo: filterData.callDateTo,
    },
  );

  const handleFilterSelection = (values) => {
    setFilterValues({ ...filterValues, ...values });
  };

  const handleApply = () => {
    const extraData = {};
    // if (filterValues.tickers && filterValues.tickers.length > 0) {
    //   if (filterData.o === null) {
    //     extraData.o = mostPopular;
    //   }
    // }
    handleWriteFilterData({ ...filterValues, ...extraData });
    toggleFilters();
  };

  const handleDateChange = (inputName, value) => {
    handleFilterSelection({
      [`${inputName}From`]: isValid(value[0]) ? formatIsoDate(value[0]) : null,
      [`${inputName}To`]: isValid(value[1]) ? formatIsoDate(value[1]) : null,
    });
  };

  const [saveUserSessionFiltersCache] = useMutation(SAVE_USER_SESSION_FILTERS);

  const handleClearFilters = () => {
    saveUserSessionFiltersCache({
      variables: {
        filterData: JSON.stringify(FILTER_INIT_VALUES),
      },
    });
    handleFilterSelection(FILTER_INIT_VALUES);
  };

  return (
    <>
      <Box mt="10px">
        <TickersFilter
          filterTickers={filterValues.tickers}
          handleFilterSelection={handleFilterSelection}
        />
      </Box>

      <Box mt="10px">
        <IndustriesFilter
          filterTickers={filterValues.industries}
          handleFilterSelection={handleFilterSelection}
        />
      </Box>

      <Box mt="10px">
        <SectorsFilter
          filterTickers={filterValues.sectors}
          handleFilterSelection={handleFilterSelection}
        />
      </Box>

      <Box className={classes.dateContainer}>
        <Box>
          <Box className={classes.label}>Publish Date:</Box>
          <DateRangePicker
            inputFormat={dateFormat}
            value={[filterValues.publishedAtFrom || null, filterValues.publishedAtTo || null]}
            onChange={(date) => handleDateChange('publishedAt', date)}
            mask=""
            renderInput={(startProps, endProps) => (
              <>
                <DatePickerField
                  {...startProps}
                  helperText=""
                  label=""
                  placeholder="From"
                />
                <DateRangeDelimiter className={classes.delimiter}>-</DateRangeDelimiter>
                <DatePickerField
                  {...endProps}
                  helperText=""
                  label=""
                  placeholder="To"
                />
              </>
            )}
          />
        </Box>
      </Box>

      <Box className={classes.dateContainer}>
        <Box>
          <Box className={classes.label}>Call Date:</Box>
          <DateRangePicker
            inputFormat={dateFormat}
            className={classes.datePicker}
            value={[filterValues.callDateFrom || null, filterValues.callDateTo || null]}
            onChange={(date) => handleDateChange('callDate', date)}
            mask=""
            renderInput={(startProps, endProps) => (
              <>
                <DatePickerField
                  {...startProps}
                  helperText=""
                  label=""
                  placeholder="Start"
                />
                <DateRangeDelimiter className={classes.delimiter}>-</DateRangeDelimiter>
                <DatePickerField
                  {...endProps}
                  helperText=""
                  label=""
                  placeholder="End"
                />
              </>
            )}
          />
        </Box>
      </Box>

      <Box mt="10px">
        <UsersFilter
          filterTickers={filterData.experts}
          handleFilterSelection={handleFilterSelection}
        />
      </Box>

      <Box mt="10px">
        <EraAutocomplete
          label="Perspective:"
          emptyLabel="All"
          limitTags={1}
          multiple
          options={allInterviewPerspective.results || []}
          value={filterValues.perspective}
          onChange={(event, value) => {
            handleFilterSelection({ perspective: value });
          }}
          getOptionLabel={(option) => option.perspective}
          getOptionSelected={(option, value) => option.perspective === value.perspective}
          disableCloseOnSelect
        />
      </Box>

      {['MEMBER', 'ANALYST'].includes(me.role)
        && (
        <Box mt="10px">
          <EraAutocomplete
            label="Compliance Status:"
            emptyLabel="All"
            limitTags={1}
            options={[
              { val: 'approved', label: 'Approved' },
              { val: 'pending', label: 'Pending' },
              { val: 'rejected', label: 'Declined' },
              { val: 'not_requested', label: 'Not Requested' },
            ]}
            value={filterValues.complianceStatus}
            onChange={(event, value) => {
              handleFilterSelection({ complianceStatus: value });
            }}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.val === value.val}
          />
        </Box>
        )}

      <Box mt="10px">
        <EraAutocomplete
          label="Transcription Status:"
          emptyLabel="All"
          limitTags={1}
          options={[
            { val: 'transcribed', label: 'Transcribed' },
            { val: 'not_transcribed', label: 'Not Transcribed' },
            { val: 'requested', label: 'Requested' },
            { val: 'not_requested', label: 'Not Requested' },
          ]}
          value={filterValues.transcriptionStatus}
          onChange={(event, value) => {
            handleFilterSelection({ transcriptionStatus: value });
          }}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.val === value.val}
        />
      </Box>

      <Box
        display="flex"
        mt="10px"
      >
        <Button
          color="inherit"
          className={classes.clearFilters}
          disableRipple
          onClick={handleClearFilters}
          startIcon={(
            <CancelRectIcon />
          )}
        >
          Clear Filters
        </Button>
      </Box>

      <Button
        onClick={handleApply}
        disableRipple
        className={classes.applyBtn}
      >
        Apply
      </Button>

    </>
  );
};

Filters.defaultProps = {
  initialValues: {},
};

Filters.propTypes = {
  initialValues: PropTypes.objectOf(PropTypes.object),
  toggleFilters: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filterData: PropTypes.object.isRequired,
  handleWriteFilterData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { filterState } = state;
  return {
    filterData: filterState.filterData,
  };
};

const areEqual = (prevProps, nextProps) => isEqual(prevProps.filterData, nextProps.filterData);

export default React.memo(
  connect(
    mapStateToProps,
    {
      handleWriteFilterData: writeFilterData,
    },
  )(Filters),
  areEqual,
);
