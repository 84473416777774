import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Fade from '@material-ui/core/Fade';
import { useMutation, useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import {
  ALL_FOLLOWING_SECTORS_QUERY,
  DELETE_SECTOR_FOLLOWING,
  UPDATE_SECTOR_MUTATION,
} from '../../../../../../../../../../queries/interview/following';
import FollowingItem from '../FollowingItem';
import ItemsSkeleton from '../../../ItemsSkeleton';
import FollowingPlaceholder from '../FollowingPlaceholder';
import FollowingItemsSection from '../FollowingItemsSection';
import SectorSelect from '../SectorSelect';
import useMe from '../../../../../../../../../../hooks/useMe';

const Accordion = withStyles(() => ({
  root: {
    borderBottom: '1px solid #EAEEF4',
    boxShadow: 'none',
    '&:last-child': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles(() => ({
  root: {
    minHeight: '30px',
    padding: '0 20px',
    '&$expanded': {
      minHeight: '30px',
    },
  },
  content: {
    margin: '6px 0',
    '&$expanded': {
      margin: '6px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: '5px 20px',
    overflow: 'auto',
    height: 'calc(100vh - 180px)',
  },
}))(MuiAccordionDetails);

const FollowingSectors = ({
  filterDataSectors, handleFilterSelection, handleExpandedAccordion, expandedAccordion,
  ariaControls, accordionId, sectionTitle, trackingClass,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { me } = useMe();

  const {
    data: {
      allSectorsWatchList = [],
    } = {},
    loading,
    updateQuery,
  } = useQuery(ALL_FOLLOWING_SECTORS_QUERY);

  const [deleteSector] = useMutation(DELETE_SECTOR_FOLLOWING, {
    refetchQueries: [{ query: ALL_FOLLOWING_SECTORS_QUERY }],
  });

  const [updateSector] = useMutation(UPDATE_SECTOR_MUTATION, {
    refetchQueries: [{ query: ALL_FOLLOWING_SECTORS_QUERY }],
  });

  const handleSectorDelete = (followingId) => {
    deleteSector({ variables: { followingId } });
  };

  const handleSectorUpdate = (followingId, sectorId) => (params) => {
    updateSector({
      variables: {
        input: {
          id: followingId,
          sector: sectorId,
          ...params,
        },
      },
    }).then(({ data: { updateSectorMutation = {} } = {} }) => {
      if (!isEmpty(updateSectorMutation.errors)) {
        enqueueSnackbar(updateSectorMutation.errors.map((err) => (
          `${err.field}: ${err.messages.join(', ')}`
        )),
        { variant: 'error', autoHideDuration: 4000 });
      } else {
        enqueueSnackbar('Saved.',
          { variant: 'success', autoHideDuration: 4000 });
      }
    });
  };
  const isSectorActive = (id) => {
    if (filterDataSectors) {
      const active = filterDataSectors.filter((el) => el.id === id);
      return active.length > 0;
    }
    return false;
  };

  const handleSelection = (item) => () => {
    handleFilterSelection({
      sectors: [{
        id: item.sector.id,
        title: item.sector.title,
        uuid: item.sector.uuid,
      }],
    });
    updateQuery((previousQueryResult) => {
      const result = previousQueryResult.allSectorsWatchList.map((el) => {
        if (el.id === item.id) {
          return { ...el, notSeenInterviewsCount: 0 };
        }
        return el;
      });
      return {
        allSectorsWatchList: result,
      };
    });
  };

  return (
    <Accordion expanded={expandedAccordion} onChange={handleExpandedAccordion}>
      <AccordionSummary aria-controls={ariaControls} id={accordionId}>
        <FollowingItemsSection
          sectionTitle={sectionTitle}
          expanded={expandedAccordion}
          totalCount={allSectorsWatchList?.length}
          unreadCount={me.sectorWatchlistUnreadCount}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Box className={trackingClass} width="100%">
          <SectorSelect />
          <Fade in={loading}>
            <Box>
              {loading && <ItemsSkeleton />}
            </Box>
          </Fade>
          <Fade in={!loading}>
            <Box>
              {allSectorsWatchList.length > 0
                ? allSectorsWatchList.map((item) => (
                  <FollowingItem
                    key={item.id}
                    data-xlink-keyword-id={item.sector.uuid}
                    itemName={item.sector.title}
                    isItemActive={isSectorActive(item.sector.id)}
                    unreadCount={item.notSeenInterviewsCount}
                    onDelete={() => handleSectorDelete(item.id)}
                    onUpdate={handleSectorUpdate(item.id, item.sector.id)}
                    onClick={handleSelection(item)}
                    initials={{
                      notify: item.notify,
                      emailAlerts: item.emailAlerts,
                    }}
                  />
                ))
                : (
                  <FollowingPlaceholder>
                    Add sectors to your watchlist to know first about new interviews
                  </FollowingPlaceholder>
                )}
            </Box>
          </Fade>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

FollowingSectors.propTypes = {
  filterDataSectors: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleFilterSelection: PropTypes.func.isRequired,
  handleExpandedAccordion: PropTypes.func.isRequired,
  expandedAccordion: PropTypes.bool.isRequired,
  ariaControls: PropTypes.string.isRequired,
  accordionId: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  trackingClass: PropTypes.string.isRequired,
};

export default FollowingSectors;
